import posthog from 'posthog-js'
import { writable } from 'svelte/store'

interface LoginResult {
  userId: number
  fullName: string
  message: string
  primaryOrganizationId: number
  primaryOrganizationName: string
  primaryRole: string
}

class User {
  username: string = ''
  userId: number = 0
  fullName: string = ''
  primaryOrganizationId: number = 0
  primaryOrganizationName: string = ''
  primaryRole: string = ''

  static login(username: string, loginResult: LoginResult) {
    if (username !== '') {
      posthog.identify(`${loginResult.userId}`, {
        name: loginResult.fullName,
        email: username,
      })
    }
    return (user: User) => {
      user.userId = loginResult.userId
      user.username = username
      user.fullName = loginResult.fullName
      user.primaryOrganizationId = loginResult.primaryOrganizationId
      user.primaryOrganizationName = loginResult.primaryOrganizationName
      user.primaryRole = loginResult.primaryRole
      return user
    }
  }
  static logout() {
    return (user: User) => {
      user.userId = 0
      user.username = ''
      user.fullName = ''
      user.primaryOrganizationId = 0
      user.primaryOrganizationName = ''
      user.primaryRole = ''
      return user
    }
  }
  get loggedIn() {
    return this.username !== ''
  }
}

const loggedInStore = writable(new User())

loggedInStore.subscribe((user) => {
  console.log('Logged-in store updated: ', user)
})

export { loggedInStore, User }
